'use strict';

// var barbaLoad = require('../../../node_modules/barba.js/');
// global.barbaLoad = barbaLoad;

// var barbaInit = require('../modules/barba_init'),
var lazyload = require('jquery-lazy'),
    lazyloadPlugin = require('../../../node_modules/jquery-lazy/jquery.lazy.plugins'),
    imgLoader = require('../modules/lazy'),
    smInit = require('scrollmagic'),
    animation = require('../../../node_modules/scrollmagic/scrollmagic/minified/plugins/animation.gsap.min.js'),
    tmInit = require('../../../node_modules/gsap/src/minified/TimelineMax.min.js');;


global.smInit = smInit;
global.animation = animation;
global.tmInit = tmInit;

var siteGlobal = {
  initReady: function () {
    // this.barbaJsInit();
    this.langsMenu();
    this.clickInit();
    this.menuInit();
    this.scrollingArrows();
    this.inputWrap();
  },
  initLoad: function () {
    this.lazyLoad();
    this.linkAll();
    this.pageVisualAnim();
    this.pageIntroAnim();
    this.wineSearchAnim();
    this.scrollTop();
  },
  initResize: function(){
  },
  initResizeEnd: function(){
  },
  initScroll: function(){
    this.headerClass();
  },
  onScroll: function(){
  },
  barbaJsInit: function(){
    barbaInit.init();
  },
  linkAll: function(){
    $('.linkFrom').each(function() { 
      var elemToLink = $(this).parents('.linkTo');
      var pageLink = $(this).attr('href');
      var linkTarget = (typeof $(this).attr('target')!=='undefined') ?  $(this).attr('target') : '' ;
      if(pageLink !== undefined && pageLink!=='javascript:;') {
        elemToLink.addClass('pointer').click({myLink:pageLink,myTarget:linkTarget},function(e){
          e.preventDefault();
          if(e.data.myTarget==='_blank'){
            window.open(e.data.myLink);
          } else {
            window.location.href = e.data.myLink;
          }
        });
      }
    }); 
  },
  lazyLoad: function(){
    imgLoader.lazyInit();
  },
  headerClass: function(){
    if ($(window).scrollTop() > 0) {
      $('.header').addClass('scrolled');
    } else {
      $('.header').removeClass('scrolled');
    }
  },
  langsMenu: function(){
    $('.header__langs').on('click', function(){
      $(this).find('.arrow').toggleClass('rot');
      $(this).find('.lang-sub-menu').fadeToggle(150);
    })
  },
  clickInit: function(){
    $('.no-click, .no-click a').on('click', function(event){
      event.preventDefault();
    })
  },
  menuInit: function(){
    $('.header__hamburger').on('click', function() {
      $('.header__hamburger, .header__menuWrap').toggleClass('is-active open');
      $('html').toggleClass('menu-open');
    })

    $('.header__menuWrap').on('touchmove scroll', function(e) {
        e.preventDefault();
        e.stopPropagation();
        return false;
    });

    $('.menu-item-has-children > a').on('click', function(event){
      event.preventDefault();
      $(this).toggleClass('active').siblings('.modNav').toggleClass('opened');
    })
  },
  scrollingArrows: function(){
    var scrollHandle = 0,
        scrollStep = 4;
    if ($(window).outerWidth(true) > 1200) {

        $('.header__arr').on('mouseenter', function () {
            var data = $(this).data('scrollModifier'),
                direction = parseInt(data, 10),
                currentEl = $(this).parent().siblings('.header__scrollCont');
            startScrolling(currentEl, direction, data);
        });

        $('.header__arr').on('mouseleave', function () {
            stopScrolling();
        });

        function startScrolling(el, modifier, step) {
            if (scrollHandle === 0) {
                scrollHandle = setInterval(function () {
                    var newOffset = el.scrollTop() + (scrollStep * modifier);
                    el.scrollTop(newOffset);
                }, 10);
            }
        }

        function stopScrolling() {
            clearInterval(scrollHandle);
            scrollHandle = 0;
        }
    }
  },
  pageVisualAnim: function(){
    var controller = new smInit.Controller(),
      tl = new tmInit(),
      pagevisEl = $('.pageVisual .container > *'),

      tween = tl.staggerTo(pagevisEl , .4, {top:0, opacity:1}, .2, "+=.4"),
      
      scene = new smInit.Scene({reverse: true, triggerElement: $('#pageIntro__trig')[0]}).setTween(tween).addTo(controller);
  },
  pageIntroAnim: function(){
    var controller = new smInit.Controller(),
      tl = new tmInit(),
      pageIntro = $('.pageIntro .container > *'),

      tween = tl.staggerTo(pageIntro , .4, {left:0, opacity:1}, .2, "+=.4"),
      
      scene = new smInit.Scene({reverse: true, triggerElement: $('#pageIntro__trig')[0]}).setTween(tween).addTo(controller);
  },
  wineSearchAnim: function(){
    var controller = new smInit.Controller(),
      tl = new tmInit(),
      wineWave = $('.wineSearch__col .wave'),
      wineTit = $('.wineSearch__title'),
      wineImg = $('.wineSearch__imgWrap'),

      tween = tl.to(wineTit, .4, {top:0,opacity:1},)
                .to(wineWave, .4, {x:0, opacity:1}, "-=.2")
                .to(wineImg, .4, {bottom:0,opacity:1}, "-=.2"),
      
      scene = new smInit.Scene({reverse: true, triggerElement: $('#wineSearch__trig')[0]}).setTween(tween).addTo(controller);
  },
  scrollTop: function(){
    $('.to_top').on('click', function(){
      event.preventDefault();
        $('html, body').animate({
            scrollTop: 0
        }, 500);
    })
  },
  inputWrap: function(){    
    $('[name="privacy"]').each(function(){
      $(this).after('<span class="check_wrap"></span>');
    })
    $('[type="submit"]').each(function(){
      $(this).wrap('<div class="submitWrap waveTitle waveTitle--purple"></div>');
    })
  },
  rebuildAllEvents: function(){
    this.initLoad();
  },
};

global.siteGlobal = siteGlobal;

(function () {

  siteGlobal.initReady();

  $( window ).on('load', function() {
    $('body, #preloader').removeClass('preload').addClass('loaded');
    siteGlobal.initLoad();
  });

  $(window).resize(function(){
    siteGlobal.initResize();
  });


  if(typeof $(window).resizeend === 'function'){
    $(window).resizeend(function () {
      siteGlobal.initResizeEnd();
    });
  } else {
    $(window).resize(function(){
      siteGlobal.initResizeEnd();
    });
  }
  $(window).scroll(function(){
    siteGlobal.initScroll();
  });

  $(window).on('scroll', function(){
    siteGlobal.onScroll();
  })
}());



